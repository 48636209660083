<script lang="ts" setup>
// Properties
const props = defineProps({
    errorMsg: {
        type: String,
        default: "",
    },
    showTooltip: {
        type: Boolean,
        default: false,
    },
    street: {
        type: String,
        default: "",
    },
    city: {
        type: String,
        default: "",
    },
    state: {
        type: String,
        default: "",
    },
    zip: {
        type: String,
        default: "",
    },
    county: {
        type: String,
        default: "",
    },
    country: {
        type: String,
        default: "",
    },
});

// Data
// Emit to update the parent components
const emit = defineEmits([
    "update:street",
    "update:city",
    "update:state",
    "update:zip",
    "update:county",
    "update:country",
]);

type EventType = {
    address_components: {
        long_name: string;
        short_name: string;
        types: string[];
    }[];
};

const autocomplete = ref();
const key = ref(new Date().getTime());

// Functions

function onInput(event: any) {
    autocomplete.value = event.target.value;
    emit("update:street", event.target.value);
}

function setAddressVariables(response: EventType) {
    const addressComponents = response.address_components;
    const street = [] as string[];
    // Update the rest of the models
    addressComponents.forEach((component) => {
        if (component.types.includes("street_number")) {
            street.push(component.long_name);
        }
        if (component.types.includes("route")) {
            street.push(component.long_name);
        }
        if (component.types.includes("locality")) {
            emit("update:city", component.long_name);
        }
        if (component.types.includes("administrative_area_level_1")) {
            emit("update:state", component.short_name);
        }
        if (component.types.includes("postal_code")) {
            emit("update:zip", component.short_name);
        }
        if (component.types.includes("administrative_area_level_2")) {
            emit("update:county", component.short_name);
        }
        if (component.types.includes("country")) {
            emit("update:country", component.short_name);
        }
    });

    if (street.length > 0) {
        emit("update:street", street.join(" "));
        autocomplete.value = street.join(" ");
    }

    key.value = new Date().getTime(); // Need to refresh the component to update the v-model
}

onMounted(() => {
    autocomplete.value = props.street;
});

watch(
    () => props.street,
    (newValue) => {
        autocomplete.value = newValue;
    },
);
</script>

<template>
    <GMapAutocomplete
        :key="key"
        v-tooltip="{
            content: errorMsg,
            shown: showTooltip ? true : false,
            triggers: [],
        }"
        placeholder="Enter your address"
        class="border px-3 py-1 shadow-none drop-shadow-none focus:border-gray-400 focus:outline-none"
        :class="!errorMsg ? 'border-gray-300 bg-gray-50' : 'border-red-700 bg-red-50'"
        :options="{
            fields: ['address_components'],
        }"
        :value="autocomplete"
        @input="onInput"
        @place_changed="setAddressVariables"
    />
</template>

<style scoped>
.input-style {
}
</style>
